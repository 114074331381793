import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>NENALEZENO</h1>
      <p>Zadanou stránku se bohužel nepodařilo najít 😭<br/>Zkuste vybrat nějakou stránku z menu.</p>
    </div>
  </Layout>
)

export default NotFoundPage
